import React from 'react';
import { PATH_DOCS } from '~/routes/paths';
import { MLabel } from '~/@material-extend';

// ----------------------------------------------------------------------

export default [
  {
    subheader: 'getting started',
    items: [
      { title: 'introduction', href: PATH_DOCS.introduction },
      { title: 'quick start', href: PATH_DOCS.started }
    ]
  },
  {
    subheader: 'Campaigning',
    items: [
      { title: 'Upload Contacts', href: PATH_DOCS.uploadContacts },
      { title: 'Write Your Templates', href: PATH_DOCS.writeYourTemplates },
      { title: 'Connecting Senders', href: PATH_DOCS.senders },
      { title: 'Sequence Setup', href: PATH_DOCS.sequence },
      { title: 'Add Custom Schedules', href: PATH_DOCS.schedules },
      { title: 'General Settings', href: PATH_DOCS.settings }
    ]
  },
  {
    subheader: 'Email Validation',
    items: [
      { title: 'How it Works', href: PATH_DOCS.validation },
      { title: 'Best Practices', href: PATH_DOCS.bestValidation },
      { title: 'Upload Your Lists', href: PATH_DOCS.uploadLists },
      { title: 'Download', href: PATH_DOCS.download },
      { title: 'Push To Campaign', href: PATH_DOCS.pushToCampaign },
    ]
  },
  {
    subheader: 'Email Warmup',
    items: [
      { title: 'How it Works', href: PATH_DOCS.warmup },
      { title: 'Best Practices', href: PATH_DOCS.bestWarmup },
      { title: 'Setup your inbox', href: PATH_DOCS.inboxSetup },
      { title: 'Advanced Settings', href: PATH_DOCS.advancedSettings },
      { title: 'Usage during campaign', href: PATH_DOCS.campaignUsage },
    ]
  },
  {
    subheader: 'Inbox Insights',
    items: [
      { title: 'How it Works', href: PATH_DOCS.insights },
      { title: 'Request a new Report', href: PATH_DOCS.reportRequest },
      { title: 'How to make the best of it', href: PATH_DOCS.howToBest },
    ]
  },
  {
    subheader: 'For Developers',
    items: [
      { title: 'Our API', href: PATH_DOCS.ourAPI },
      { title: 'Getting Access', href: PATH_DOCS.gettingAccess },
      { title: 'Rate Limits', href: PATH_DOCS.rateLimits },
      { title: 'Example Usage', href: PATH_DOCS.example },
    ]
  },
  {
    subheader: 'support & changelog',
    items: [
      { title: 'support', href: PATH_DOCS.support },
      {
        title: 'changelog',
        href: PATH_DOCS.changelog,
        info: (
          <MLabel
            variant="filled"
            color="info"
            sx={{ textTransform: 'lowercase' }}
          >
            v1.0.0
          </MLabel>
        )
      }
    ]
  }
];
