import React from 'react';
import { MLabel, MIcon } from '~/@material-extend';
import { PATH_APP } from '~/routes/paths';

// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  validator: <MIcon src={path('ic_validate-message')} />,
  fire: <MIcon src={path('ic_fire')} />,
  scan: <MIcon src={path('ic_magnify-scan')} />,
  people: <MIcon src={path('ic_account-group')} />,
  api: <MIcon src={path('ic_chip')} />,
  money: <MIcon src={path('ic_cash-usd')} />,
  profilesettings: <MIcon src={path('ic_account-cog-outline')} />,
  beta: <MIcon src={path('ic_new-box')} />,
};

export default [
  //PEAK DASHBOARD - Email Campaigns
  // ----------------------------------------------------------------------
  {
    subheader: 'Email Campaigns',
    items: [
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.main.dashboard,
        items: [
          {
            title: 'my campaigns',
            href: PATH_APP.main.mycampaigns
          },
          {
            title: 'templates',
            href: PATH_APP.main.templates
          },
          {
            title: 'senders',
            href: PATH_APP.main.senders
          },
          {
            title: 'Domains',
            href: PATH_APP.main.domains
          },
          {
            title: 'Reports',
            href: PATH_APP.main.reports
          }
        ]
      }
    ]
  },
  //PEAK DASHBOARD - Prospects
  // ----------------------------------------------------------------------

  {
    subheader: 'prospects',
    items: [
      {
        title: 'my lists',
        icon: ICONS.people,
        href: PATH_APP.prospects.mylists.root,
        items: [
          {
            title: 'people',
            href: PATH_APP.prospects.mylists.people
          },
          {
            title: 'buckets',
            href: PATH_APP.prospects.mylists.buckets
          },
          {
            title: 'blacklist',
            href: PATH_APP.prospects.mylists.blacklist
          },
        ]
      },

      // PEAK : Email Validation
      // ----------------------------------------------------------------------
      {
        title: 'email validation',
        icon: ICONS.validator,
        href: PATH_APP.prospects.emailvalidation.root,
        info: (
          <MLabel variant="filled" color="warning">
            1+
          </MLabel>
        ),
        items: [
          {
            title: 'upload',
            href: PATH_APP.prospects.emailvalidation.upload,
            info: (
              <MLabel variant="filled" color="warning">
                1+
              </MLabel>
            ),
          },
          {
            title: 'lists',
            href: PATH_APP.prospects.emailvalidation.lists
          }
        ]
      },
    ]
  },
   // PEAK: Lab
  // ----------------------------------------------------------------------
  {
    subheader: 'Peak Lab',
    items: [
      {
        title: 'email warmup',
        icon: ICONS.fire,
        href: PATH_APP.peaklab.emailwarmup.root,
        info: (
                  <MLabel variant="filled" color="success">
                    1+
                  </MLabel>
                ),
        items: [
          
          {
            title: 'My Slots',
            href: PATH_APP.peaklab.emailwarmup.myslots,
            info: (
              <MLabel variant="filled" color="success">
                1+
              </MLabel>
            ),
          },
          {
            title: 'settings',
            href: PATH_APP.peaklab.emailwarmup.settings
          },
        ]
      },
      {
        title: 'Inbox Insights',
        icon: ICONS.scan,
        href: PATH_APP.peaklab.inboxinsights.root,
        items: [
          {
            title: 'setup',
            href: PATH_APP.peaklab.inboxinsights.setup
          },
          {
            title: 'reports',
            href: PATH_APP.peaklab.inboxinsights.reports
          },
        ]
      },
    ]
  },
  // PEAK: API
  // ----------------------------------------------------------------------
  {
    subheader: 'FOR DEVELOPERS',
    items: [
      {
        title: 'api',
        icon: ICONS.api,
        href: PATH_APP.developers.accesskey,
        items: [
          {
            title: 'access key',
            href: PATH_APP.developers.accesskey
          },
        ]
      }
    ]
  },
  // PEAK: ACCOUNT MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'account settings',
        icon: ICONS.profilesettings,
        href: PATH_APP.management1.accountsettings
      },
      {
        title: 'plans and billing',
        icon: ICONS.money,
        href: PATH_APP.management1.plans_and_billing.root,
        items: [
          // {
          //   title: 'my plan',
          //   href: PATH_APP.management1.plans_and_billing.myplan,
          // },
          {
            title: 'invoices',
            href: PATH_APP.management1.plans_and_billing.invoices
          },
          {
            title: 'available plans',
            href: PATH_APP.management1.plans_and_billing.availableplans
          },
        ]
      },
      {
        title: 'beta',
        icon: ICONS.beta,
        href: PATH_APP.beta.beta,
      },

  //     // MANAGEMENT : E-COMMERCE
  //     // ----------------------------------------------------------------------
  //     {
  //       title: 'e-commerce',
  //       icon: ICONS.cart,
  //       href: PATH_APP.management.eCommerce.root,
  //       items: [
  //         {
  //           title: 'shop',
  //           href: PATH_APP.management.eCommerce.products
  //         },
  //         {
  //           title: 'product',
  //           href: PATH_APP.management.eCommerce.productById
  //         },
  //         {
  //           title: 'list',
  //           href: PATH_APP.management.eCommerce.list
  //         },
  //         {
  //           title: 'checkout',
  //           href: PATH_APP.management.eCommerce.checkout
  //         },
  //         {
  //           title: 'invoice',
  //           href: PATH_APP.management.eCommerce.invoice
  //         }
  //       ]
  //     },

  //     // MANAGEMENT : BLOG
  //     // ----------------------------------------------------------------------
  //     {
  //       title: 'blog',
  //       icon: ICONS.blog,
  //       href: PATH_APP.management.blog.root,
  //       items: [
  //         {
  //           title: 'posts',
  //           href: PATH_APP.management.blog.root
  //         },
  //         {
  //           title: 'post',
  //           href: PATH_APP.management.blog.postById
  //         },
  //         {
  //           title: 'new post',
  //           href: PATH_APP.management.blog.newPost
  //         }
  //       ]
  //     }
  //   ]
  // },

  // // MAIN DASHBOARD
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'generall',
  //   items: [
  //     {
  //       title: 'dashboard',
  //       icon: ICONS.dashboard,
  //       href: PATH_APP.main.root,
  //       items: [
  //         {
  //           title: 'app',
  //           href: PATH_APP.main.root
  //         },
  //         {
  //           title: 'e-commerce',
  //           href: PATH_APP.main.ecommerce
  //         },
  //         {
  //           title: 'analytics',
  //           href: PATH_APP.main.analytics
  //         },
  //         {
  //           title: 'Loading',
  //           href: PATH_APP.main.testpath
  //         }
  //       ]
  //     }
  //   ]
  // },
  

  // // MANAGEMENT
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       icon: ICONS.user,
  //       href: PATH_APP.management.user.root,
  //       items: [
  //         {
  //           title: 'profile',
  //           href: PATH_APP.management.user.profile
  //         },
  //         {
  //           title: 'cards',
  //           href: PATH_APP.management.user.cards
  //         },
  //         {
  //           title: 'list',
  //           href: PATH_APP.management.user.list
  //         },
  //         {
  //           title: 'account',
  //           href: PATH_APP.management.user.account
  //         }
  //       ]
  //     },

  //     // MANAGEMENT : E-COMMERCE
  //     // ----------------------------------------------------------------------
  //     {
  //       title: 'e-commerce',
  //       icon: ICONS.cart,
  //       href: PATH_APP.management.eCommerce.root,
  //       items: [
  //         {
  //           title: 'shop',
  //           href: PATH_APP.management.eCommerce.products
  //         },
  //         {
  //           title: 'product',
  //           href: PATH_APP.management.eCommerce.productById
  //         },
  //         {
  //           title: 'list',
  //           href: PATH_APP.management.eCommerce.list
  //         },
  //         {
  //           title: 'checkout',
  //           href: PATH_APP.management.eCommerce.checkout
  //         },
  //         {
  //           title: 'invoice',
  //           href: PATH_APP.management.eCommerce.invoice
  //         }
  //       ]
  //     },

  //     // MANAGEMENT : BLOG
  //     // ----------------------------------------------------------------------
  //     {
  //       title: 'blog',
  //       icon: ICONS.blog,
  //       href: PATH_APP.management.blog.root,
  //       items: [
  //         {
  //           title: 'posts',
  //           href: PATH_APP.management.blog.root
  //         },
  //         {
  //           title: 'post',
  //           href: PATH_APP.management.blog.postById
  //         },
  //         {
  //           title: 'new post',
  //           href: PATH_APP.management.blog.newPost
  //         }
  //       ]
  //     }
  //   ]
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       href: PATH_APP.app.mail.root,
  //       icon: ICONS.mail
  //     },
  //     {
  //       title: 'chat',
  //       href: PATH_APP.app.chat.root,
  //       icon: ICONS.chat
  //     },
  //     {
  //       title: 'calendar',
  //       href: PATH_APP.app.calendar,
  //       icon: ICONS.calendar
  //     }
  //   ]
  // },

  // // PAGES
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'pages',
  //   items: [
  //     {
  //       title: 'auth',
  //       href: PATH_PAGE.auth.loginUnprotected,
  //       icon: ICONS.authenticator,
  //       items: [
  //         {
  //           title: 'login',
  //           href: PATH_PAGE.auth.loginUnprotected
  //         },
  //         {
  //           title: 'register',
  //           href: PATH_PAGE.auth.registerUnprotected
  //         },
  //         {
  //           title: 'reset password',
  //           href: PATH_PAGE.auth.resetPassword
  //         },
  //         {
  //           title: 'verify code',
  //           href: PATH_PAGE.auth.verify
  //         }
  //       ]
  //     },
  //     {
  //       title: 'errors & status',
  //       href: '/404',
  //       icon: ICONS.error,
  //       items: [
  //         {
  //           title: 'page 404',
  //           href: '/404'
  //         },
  //         {
  //           title: 'page 500',
  //           href: '/500'
  //         },
  //         {
  //           title: 'maintenance',
  //           href: PATH_PAGE.maintenance
  //         },
  //         {
  //           title: 'coming soon',
  //           href: PATH_PAGE.comingSoon
  //         }
  //       ]
  //     },
  //     {
  //       title: 'landing page',
  //       href: '/',
  //       icon: ICONS.page
  //     },
  //     {
  //       title: 'pricing',
  //       href: PATH_PAGE.pricing,
  //       icon: ICONS.page
  //     },
  //     {
  //       title: 'payment',
  //       href: PATH_PAGE.payment,
  //       icon: ICONS.page
  //     }
  //   ]
  // },

  // // UI KIT
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'UI kit',
  //   items: [
  //     {
  //       // FOUNDATIONS
  //       // ----------------------------------------------------------------------
  //       title: 'foundations',
  //       href: PATH_APP.foundations.root,
  //       icon: ICONS.elements,
  //       items: [
  //         {
  //           title: 'colors',
  //           href: PATH_APP.foundations.colors
  //         },
  //         {
  //           title: 'typography',
  //           href: PATH_APP.foundations.typography
  //         },
  //         {
  //           title: 'shadows',
  //           href: PATH_APP.foundations.shadows
  //         },
  //         {
  //           title: 'grid',
  //           href: PATH_APP.foundations.grid
  //         },
  //         {
  //           title: 'icons',
  //           href: PATH_APP.foundations.icons
  //         }
  //       ]
  //     },
  //     {
  //       // COMPONENTS
  //       // ----------------------------------------------------------------------
  //       title: 'components',
  //       href: PATH_APP.components.root,
  //       icon: ICONS.components,
  //       info: (
  //         <MLabel variant="filled" color="error">
  //           32+
  //         </MLabel>
  //       ),
  //       items: [
  //         {
  //           title: 'accordion',
  //           href: PATH_APP.components.accordion
  //         },
  //         {
  //           title: 'alert',
  //           href: PATH_APP.components.alert
  //         },
  //         {
  //           title: 'autocomplete',
  //           href: PATH_APP.components.autocomplete
  //         },
  //         {
  //           title: 'avatar',
  //           href: PATH_APP.components.avatar
  //         },
  //         {
  //           title: 'badge',
  //           href: PATH_APP.components.badge
  //         },
  //         {
  //           title: 'breadcrumbs',
  //           href: PATH_APP.components.breadcrumbs
  //         },
  //         {
  //           title: 'buttons',
  //           href: PATH_APP.components.buttons
  //         },
  //         {
  //           title: 'chip',
  //           href: PATH_APP.components.chip
  //         },
  //         {
  //           title: 'dialog',
  //           href: PATH_APP.components.dialog
  //         },
  //         {
  //           title: 'label',
  //           href: PATH_APP.components.label
  //         },
  //         {
  //           title: 'lists',
  //           href: PATH_APP.components.lists
  //         },
  //         {
  //           title: 'menu',
  //           href: PATH_APP.components.menu
  //         },
  //         {
  //           title: 'pagination',
  //           href: PATH_APP.components.pagination
  //         },
  //         {
  //           title: 'pickers',
  //           href: PATH_APP.components.pickers
  //         },
  //         {
  //           title: 'popover',
  //           href: PATH_APP.components.popover
  //         },
  //         {
  //           title: 'progress',
  //           href: PATH_APP.components.progress
  //         },
  //         {
  //           title: 'rating',
  //           href: PATH_APP.components.rating
  //         },
  //         {
  //           title: 'selection controls',
  //           href: PATH_APP.components.selectionControls
  //         },
  //         {
  //           title: 'slider',
  //           href: PATH_APP.components.slider
  //         },
  //         {
  //           title: 'snackbar',
  //           href: PATH_APP.components.snackbar
  //         },
  //         {
  //           title: 'stepper',
  //           href: PATH_APP.components.stepper
  //         },
  //         {
  //           title: 'tabs',
  //           href: PATH_APP.components.tabs
  //         },
  //         {
  //           title: 'table',
  //           href: PATH_APP.components.table
  //         },
  //         {
  //           title: 'text field',
  //           href: PATH_APP.components.textfield
  //         },
  //         {
  //           title: 'timeline',
  //           href: PATH_APP.components.timeline
  //         },
  //         {
  //           title: 'tooltip',
  //           href: PATH_APP.components.tooltip
  //         },
  //         {
  //           title: 'transfer list',
  //           href: PATH_APP.components.transferList
  //         },
  //         {
  //           title: 'tree view',
  //           href: PATH_APP.components.treeView
  //         }
  //       ]
  //     },

  //     // EXTRA COMPONENTS
  //     // ----------------------------------------------------------------------
  //     {
  //       title: 'charts',
  //       href: PATH_APP.components.chart.root,
  //       icon: ICONS.charts,
  //       items: [
  //         {
  //           title: 'apexcharts',
  //           href: PATH_APP.components.chart.apexcharts
  //         },
  //         {
  //           title: 'recharts',
  //           href: PATH_APP.components.chart.recharts
  //         }
  //       ]
  //     },
  //     {
  //       title: 'map',
  //       href: PATH_APP.components.map.root,
  //       icon: ICONS.map,
  //       items: [
  //         {
  //           title: 'google map',
  //           href: PATH_APP.components.map.google
  //         },
  //         {
  //           title: 'mapbox',
  //           href: PATH_APP.components.map.mapbox
  //         }
  //       ]
  //     },
  //     {
  //       title: 'editor',
  //       href: PATH_APP.components.editor,
  //       icon: ICONS.editor
  //     },
  //     {
  //       title: 'copy To clipboard',
  //       href: PATH_APP.components.copyToClipboard,
  //       icon: ICONS.copy
  //     },
  //     {
  //       title: 'upload',
  //       href: PATH_APP.components.upload,
  //       icon: ICONS.upload
  //     },
  //     {
  //       title: 'carousel',
  //       href: PATH_APP.components.carousel,
  //       icon: ICONS.carousel
  //     },
  //     // {
  //     //   title: 'multi language',
  //     //   href: PATH_APP.components.multiLanguage,
  //     //   icon: ICONS.language
  //     // }
    ]
  }
];
