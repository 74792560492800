import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DOCS } from '~/routes/paths';
import DocsLayout from '~/layouts/DocsLayout';

// ----------------------------------------------------------------------

const DocsRoutes = {
  path: PATH_DOCS.root,
  layout: DocsLayout,
  routes: [
    // GETTING STARTED
    {
      exact: true,
      path: PATH_DOCS.introduction,
      component: lazy(() =>
        import('src/views/docs/getting-started/IntroductionView')
      )
    },

    {
      exact: true,
      path: PATH_DOCS.started,
      component: lazy(() =>
        import('src/views/docs/getting-started/QuickStartView')
      )
    },

    // CAMPAIGNING
    {
      exact: true,
      path: PATH_DOCS.uploadContacts,
      component: lazy(() => import('src/views/docs/campaigning/UploadContactsView'))
    },
    {
      exact: true,
      path: PATH_DOCS.writeYourTemplates,
      component: lazy(() => import('src/views/docs/campaigning/WriteYourTemplatesView'))
    },
    {
      exact: true,
      path: PATH_DOCS.senders,
      component: lazy(() => import('src/views/docs/campaigning/ConnectingSendersView'))
    },
    {
      exact: true,
      path: PATH_DOCS.sequence,
      component: lazy(() => import('src/views/docs/campaigning/SequenceSetupView'))
    },
    {
      exact: true,
      path: PATH_DOCS.schedules,
      component: lazy(() => import('src/views/docs/campaigning/AddCustomSchedulesView'))
    },
    {
      exact: true,
      path: PATH_DOCS.settings,
      component: lazy(() => import('src/views/docs/campaigning/GeneralSettingsView'))
    },

    // EMAIL VALIDATION
    {
      exact: true,
      path: PATH_DOCS.validation,
      component: lazy(() => import('src/views/docs/email-validation/HowItWorksView'))
    },
    {
      exact: true,
      path: PATH_DOCS.bestValidation,
      component: lazy(() => import('src/views/docs/email-validation/BestPracticesView'))
    },
    {
      exact: true,
      path: PATH_DOCS.uploadLists,
      component: lazy(() => import('src/views/docs/email-validation/UploadYourListsView'))
    },
    {
      exact: true,
      path: PATH_DOCS.download,
      component: lazy(() => import('src/views/docs/email-validation/DownloadView'))
    },
    {
      exact: true,
      path: PATH_DOCS.pushToCampaign,
      component: lazy(() => import('src/views/docs/email-validation/PushToCampaignView'))
    },

    // EMAIL WARMUP
    {
      exact: true,
      path: PATH_DOCS.warmup,
      component: lazy(() => import('src/views/docs/email-warmup/HowItWorksView'))
    },
    {
      exact: true,
      path: PATH_DOCS.bestWarmup,
      component: lazy(() => import('src/views/docs/email-warmup/BestPracticesView'))
    },
    {
      exact: true,
      path: PATH_DOCS.inboxSetup,
      component: lazy(() => import('src/views/docs/email-warmup/SetupYourInboxView'))
    },
    {
      exact: true,
      path: PATH_DOCS.advancedSettings,
      component: lazy(() => import('src/views/docs/email-warmup/AdvancedSettingsView'))
    },
    {
      exact: true,
      path: PATH_DOCS.campaignUsage,
      component: lazy(() => import('src/views/docs/email-warmup/UsageDuringCampaign'))
    },

    // INBOX INSIGHTS
    {
      exact: true,
      path: PATH_DOCS.insights,
      component: lazy(() => import('src/views/docs/inbox-insights/HowItWorksView'))
    },
    {
      exact: true,
      path: PATH_DOCS.reportRequest,
      component: lazy(() => import('src/views/docs/inbox-insights/RequestANewReportView'))
    },
    {
      exact: true,
      path: PATH_DOCS.howToBest,
      component: lazy(() => import('src/views/docs/inbox-insights/HowToMakeTheBestOfIt'))
    },

    // FOR DEVELOPERS
    {
      exact: true,
      path: PATH_DOCS.ourAPI,
      component: lazy(() => import('src/views/docs/for-developers/OurAPIView'))
    },
    {
      exact: true,
      path: PATH_DOCS.gettingAccess,
      component: lazy(() => import('src/views/docs/for-developers/GettingAccessView'))
    },
    {
      exact: true,
      path: PATH_DOCS.rateLimits,
      component: lazy(() => import('src/views/docs/for-developers/RateLimitsView'))
    },
    {
      exact: true,
      path: PATH_DOCS.example,
      component: lazy(() => import('src/views/docs/for-developers/ExampleUsageView'))
    },

    // SUPPORT & CHANGELOG
    {
      exact: true,
      path: PATH_DOCS.support,
      component: lazy(() => import('src/views/docs/support-log/SupportView'))
    },
    {
      exact: true,
      path: PATH_DOCS.changelog,
      component: lazy(() => import('src/views/docs/support-log/ChangelogView'))
    },
    {
      exact: true,
      path: PATH_DOCS.root,
      component: () => <Redirect to={PATH_DOCS.introduction} />
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DocsRoutes;
