import faker from 'faker';
import mock from '~/utils/mock';
import { orderBy } from 'lodash';
import { set, sub } from 'date-fns';

// ----------------------------------------------------------------------

let notifications = [
  {
    id: faker.random.uuid(),
    title: 'Warmup completed on slot 2',
    description: 'the sender is ready for campaign',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.random.uuid(),
    title: 'List FIN_CONSTRUCTION_batch3 finished validation',
    description: 'we found 1541 good emails',
    avatar: null,
    type: 'order_placed',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true
  },
  {
    id: faker.random.uuid(),
    title: 'Campaign EPG Insurance finished!',
    description: 'with an average open rate of 16',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.random.uuid(),
    title: 'Campaign Finance_4 finished!',
    description: 'with an average open rate of 31',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false
  },
  {
    id: faker.random.uuid(),
    title: 'List finance_large_usa_232 finished validation',
    description: 'we found 8774 good emails',
    avatar: null,
    type: 'order_placed',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: false
  }
];

// ----------------------------------------------------------------------

mock.onGet('/api/notifications').reply(() => {
  notifications = orderBy(notifications, ['createdAt'], ['desc']);

  return [200, { notifications }];
});

// ----------------------------------------------------------------------
