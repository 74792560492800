import React from 'react';
import { Redirect } from 'react-router-dom';
// import HomeLayout from '~/layouts/HomeLayout';
import { PATH_APP } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  // layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={PATH_APP.main.dashboard} />
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
