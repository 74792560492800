import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '~/components/Auth/AuthProtect';
import DashboardLayout from '~/layouts/DashboardLayout';

// ----------------------------------------------------------------------


const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [

    // MAIN DASHBOARD
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.main.root,
      component: () => <Redirect to={PATH_APP.main.mycampaigns} />
    },
    {
      exact: true,
      path: PATH_APP.main.dashboard,
      component: lazy(() => import('src/views/email_campaigns/DashboardView'))
    },
    {
      exact: true,
      path: PATH_APP.main.mycampaigns,
      component: lazy(() => import('src/views/email_campaigns/MyCampaignsView'))
    },
    {
      exact: true,
      path: PATH_APP.main.templates,
      component: lazy(() => import('src/views/email_campaigns/TemplatesView'))
    },
    {
      exact: true,
      path: PATH_APP.main.senders,
      component: lazy(() => import('src/views/email_campaigns/SendersView'))
    },
    {
      exact: true,
      path: PATH_APP.main.domains,
      component: lazy(() => import('src/views/email_campaigns/DomainsView'))
    },
    {
      exact: true,
      path: PATH_APP.main.reports,
      component: lazy(() => import('src/views/email_campaigns/ReportsView'))
    },

    // PROSPECTS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.prospects.mylists.root,
      component: () => <Redirect to={PATH_APP.prospects.mylists.people} />
    },
    {
      exact: true,
      path: PATH_APP.prospects.mylists.people,
      component: lazy(() => import('src/views/prospects/MyListsView/PeopleView'))
    },
    {
      exact: true,
      path: PATH_APP.prospects.mylists.buckets,
      component: lazy(() => import('src/views/prospects/MyListsView/BucketsView'))
    },
    {
      exact: true,
      path: PATH_APP.prospects.mylists.blacklist,
      component: lazy(() => import('src/views/prospects/MyListsView/BlacklistView'))
    },
    {
      exact: true,
      path: PATH_APP.prospects.emailvalidation.root,
      component: () => <Redirect to={PATH_APP.prospects.emailvalidation.upload} />
    },
    {
      exact: true,
      path: PATH_APP.prospects.emailvalidation.upload,
      component: lazy(() => import('src/views/prospects/EmailValidationView/UploadView'))
    },
    {
      exact: true,
      path: PATH_APP.prospects.emailvalidation.lists,
      component: lazy(() => import('src/views/prospects/EmailValidationView/ListsView'))
    },

    // PEAK LAB
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.peaklab.emailwarmup.root,
      component: () => <Redirect to={PATH_APP.peaklab.emailwarmup.myslots} />
    },
    {
      exact: true,
      path: PATH_APP.peaklab.emailwarmup.myslots,
      component: lazy(() => import('src/views/peak_lab/EmailWarmupView/MySlotsView'))
    },
    {
      exact: true,
      path: PATH_APP.peaklab.emailwarmup.settings,
      component: lazy(() => import('src/views/peak_lab/EmailWarmupView/SettingsView'))
    },
    {
      exact: true,
      path: PATH_APP.peaklab.inboxinsights.root,
      component: () => <Redirect to={PATH_APP.peaklab.inboxinsights.setup} />
    },
    {
      exact: true,
      path: PATH_APP.peaklab.inboxinsights.setup,
      component: lazy(() => import('src/views/peak_lab/InboxInsightsView/SetupView'))
    }, {
      exact: true,
      path: PATH_APP.peaklab.inboxinsights.reports,
      component: lazy(() => import('src/views/peak_lab/InboxInsightsView/ReportsView'))
    },

    // FOR DEVELOPERS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.developers.accesskey,
      component: lazy(() => import('src/views/developers/AccessKeyView'))
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------

    {
      exact: true,
      path: PATH_APP.management1.accountsettings,
      component: lazy(() => import('src/views/management/AccountSettingsView'))
    },
    {
      exact: true,
      path: PATH_APP.management1.plans_and_billing.root,
      component: () => <Redirect to={PATH_APP.management1.plans_and_billing.myplan} />
    },
    // {
    //   exact: true,
    //   path: PATH_APP.management1.plans_and_billing.myplan,
    //   component: lazy(() => import('src/views/management/PlansAndBillingView/MyPlanView'))
    // },
    {
      exact: true,
      path: PATH_APP.management1.plans_and_billing.invoices,
      component: lazy(() => import('src/views/management/PlansAndBillingView/InvoicesView'))
    },
    {
      exact: true,
      path: PATH_APP.management1.plans_and_billing.availableplans,
      component: lazy(() => import('src/views/management/PlansAndBillingView/AvailablePlansView'))
    },
    {
      exact: true,
      path: PATH_APP.management1.plans_and_billing.payment,
      component: lazy(() => import('src/views/management/PlansAndBillingView/PaymentView'))
    },
     // BETA
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.beta.beta,
      component: lazy(() => import('~/views/BetaView'))
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
